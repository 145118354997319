import React from 'react';
import { Helmet } from 'react-helmet'; // For dynamic meta tags
import '../css/VenuesPage.css';
import Blobs from '../Components/Blobs';

import baad from '../img/venue_img/baad.jpeg'
import room from '../img/venue_img/room.jpg'
import slay from '../img/venue_img/slay.jpeg'
import stereo from '../img/venue_img/stereo.webp'

const venues = [
  {
    name: "BAaD",
    accent: "Venue & Box Office",
    description: "BAaD is a staple venue in the heart of the vibrant Glasgow Barrowlands. Enjoy market stalls by locally based artists. ",
    descriptionLine: "Please do take some time to have a look at all these stalls and support the wonderful Glasgow DIY scene.",
    descriptionLineTwo: "We are in partnership with Thistly Cross cider and Tempest Brewery to deliver the tastiest beverages this side of planet earth. Under 18's must be accompanied by an adult.",
    image: baad
  },
  {
    name: "Room 2",
    accent: "Club Venue",
    description: "Room 2 boasts both a finely-tuned Funktion-1 soundsystem and an incredible lighting rig, including a 2-tier video wall.",
    descriptionLine: "Please do take some time to have a look at all these stalls and support the wonderful Glasgow DIY scene.",
    descriptionLineTwo: "We are in partnership with Thistly Cross cider and Tempest Brewery to deliver the tastiest beverages this side of planet earth. Unfortunately, we cannot allow under 18's into Room 2.",
    image: room
  },
  {
    name: "SLAY",
    accent: "Venue",
    description: "Slay is a venue in glasgow thayt I know very little about so I need to remember to chane this text and google some stuff about slay.",
    descriptionLine: "Please do take some time to have a look at all these stalls and support the wonderful Glasgow DIY scene.",
    descriptionLineTwo: "We are in partnership with Thistly Cross cider and Tempest Brewery to deliver the tastiest beverages this side of planet earth. Unfortunately, we cannot allow under 18's into Slay.",
    image: slay
  },
  {
    name: "Stereo",
    accent: "Venue",
    description: "Holding down (x amount) of years in Glasgows city centre as a multi-faceted venue, from great vegan food to intimate gigs and high energy club nights, Stereo holds down a significant spot in Glasgow's best of.",
    descriptionLine: "Please do take some time to have a look at all these stalls and support the wonderful Glasgow DIY scene.",
    descriptionLineTwo: "We are in partnership with Thistly Cross cider and Tempest Brewery to deliver the tastiest beverages this side of planet earth. Unfortunately, we cannot allow under 18's into Stereo.",
    image: stereo
  }
];

const VenuesPage = () => {
  return (
    <>
      <Helmet>
        <title>Venues - Wastelands Festival 2025</title>
        <meta name="description" content="Partner clubs and venues hosting Wasteland Festival 2025." />
      </Helmet>

      <section className="venues-page">
        <React.Suspense fallback={<div>Loading...</div>}>
          <Blobs />
        </React.Suspense>

        <div className='venues-container'>
          <div className="venues-header">
            <h1>Our Venues</h1>
            <h2>Explore our partner venues</h2>
          </div>

          <div className="venues-info">
            <h4 className="courier">Wasteland Festival is hosted across four of Glasgow's iconic venues, offering a selection of local vendors, gigs and clubs.</h4>
          </div>

          <div className="venues-list-container">
            {venues.map((venue, index) => (
              <div key={venue.name} className={`venue ${index % 2 === 0 ? 'image-left' : 'image-right'}`}>
                <img src={venue.image} alt={venue.name} className="venue-image" />
                <div className="venue-text">
                  <h4 className="venue-accent">{venue.accent}</h4>
                  <h1 className='venue-title'>{venue.name}</h1>
                  <div className='venue-description-container'>
                    <p className="courier">{venue.description}</p>
                    <p className="courier">{venue.descriptionLine}</p>
                    <p className="courier">{venue.descriptionLineTwo}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="venue-link-container">
            <a 
              href="https://www.seetickets.com/event/wastelands-festival-2025/rule-valley-escapes/3194590" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="ticket-link"
              aria-label="Buy tickets for Wastelands Festival 2025 on SeeTickets"
            >
              Buy Tickets
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default VenuesPage;
