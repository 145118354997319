import React from 'react';
import Highlight from '../Highlight.svg';

const Hero = ({ activeSection }) => {
  return (
    <section className={`hero section ${activeSection === 'lineup' ? 'active' : ''}`} id="home">
      <div className="hero-container">
        

        <div className='logo-container'>
        <div className='hero-text-wrapper'>
            <div className='hero-text-container'>
              <h2>6th - 8th JUNE 2025 /</h2>
            </div>
            <div className='hero-text-container'>
              <h2>/  GLASGOW SCOTLAND</h2>
            </div>
        </div>
          
          <div className='logo-content'></div>
          
          <div className='hero-text-container hero-text-container-bottom'>
              <h3>Wastelands Festival is a vibrant and inclusive celebration, offering a unique and creative experience for everyone to enjoy.</h3>
            </div>
            <div className='hero-text-container-link'>
              <a href="#info">Find out more</a>
            </div>
          

        </div>

        <img
          src={Highlight}
          alt="Highlight"
          className="highlight-svg"
        />

      </div>
    </section>
  );
};

export default Hero;
