import React from 'react';
import { Helmet } from 'react-helmet'; // For dynamic meta tags
import '../css/TicketPage.css';
import Blobs from '../Components/Blobs';

const TicketPage = () => {
  return (
    <>
      <Helmet>
        <title>Tickets - Wastelands Festival 2025</title>
        <meta name="description" content="Get your tickets for Wastelands Festival 2025, including early bird and general sale options." />
        <meta name="keywords" content="Wastelands Festival 2025, festival tickets, early bird tickets, general sale tickets, festival pricing" />
        <meta property="og:description" content="Get your tickets for Wastelands Festival 2025 now! Early bird and general sale tickets available." />
        <meta property="og:url" content="https://www.seetickets.com/event/wastelands-festival-2025/rule-valley-escapes/3194590" />
      </Helmet>

      <section className="ticket-page">
        {/* Lazy-load the Blobs component */}
        <React.Suspense fallback={<div>Loading...</div>}>
          <Blobs />
        </React.Suspense>

        <div className='ticket-container'>
          <div className="ticket-header">
            <h1>Wastelands Festival 2025</h1>
            <h2>Rule Valley Escapes</h2>
          </div>

          <div className="ticket-info">
            <h3>Ticket Pricing</h3>
            <ul className="ticket-list">
              <li>
                <span className="ticket-type courier">Early Bird Ticket</span>
                <span className="ticket-price courier">£50.00</span>
              </li>
              <li>
                <span className="ticket-type courier">General Sale Tickets</span>
                <span className="ticket-price courier">£100.00</span>
              </li>
              {/* <li>
                <span className="ticket-type courier">Car Park Weekend Ticket</span>
                <span className="ticket-price courier">£35.00</span>
              </li>
              <li>
                <span className="ticket-type courier">Live In Vehicle Weekend Ticket</span>
                <span className="ticket-price courier">£25.00</span>
              </li> */}
            </ul>
          </div>

          <div className="ticket-info-container">
            <p className='courier'>
            £3 of each ticket sale will go towards Trees+, a project that plants trees, restores and preserves threatened ecosystems and supports communities on the front line of climate change. <br></br> <br></br>
            Wastelands Festival is raising funds to support Onboard:Earth's environmental restoration project to address unavoidable travel emissions. You can find more information on their website at <a href="https://onboard.earth/" className='courier' rel="noreferrer" target="_blank">Onboard:Earth</a> and in the Partners section.<br></br> <br></br>
            We appreciate each and every one of the contributions towards this cause.<br></br>
            </p>
          </div>

          <div className="ticket-link-container">
            <a 
              href="https://www.seetickets.com/event/wastelands-festival-2025/rule-valley-escapes/3194590" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="ticket-link"
              aria-label="Buy tickets for Wastelands Festival 2025 on SeeTickets"
            >
              Buy Tickets
            </a>
          </div>
        </div>

        {/* Adding structured data using JSON-LD for SEO */}
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "Event",
                "name": "Wastelands Festival 2025",
                "startDate": "2025-06-12",
                "endDate": "2025-06-14",
                "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
                "eventStatus": "https://schema.org/EventScheduled",
                
                "offers": {
                  "@type": "Offer",
                  "url": "https://www.seetickets.com/event/wastelands-festival-2025/rule-valley-escapes/3194590",
                  "priceCurrency": "GBP",
                  "price": "50.00",
                  "category": "early bird",
                  "availability": "https://schema.org/InStock",
                  "validFrom": "2024-06-01"
                },
                "performer": {
                  "@type": "MusicGroup",
                  "name": "TBD"
                },
                "description": "Get your tickets for Wastelands Festival 2025, including early bird and general sale options."
              }
            `}
          </script>
        </Helmet>
      </section>
    </>
  );
};

export default TicketPage;
